<template>
    <div class="right_main">
        <div class="msg"><span class="el-icon-arrow-left"
                style="font-size: 24px;font-weight: 600;cursor: pointer;margin-right: 6px;"
                @click="handle_goback"></span>{{title}}</div>
        <div class="rige">
            <div class="maleft">
                <!-- 页面标题  按钮部分 -->
                <div class="top_btn">
                    <!-- 页面标题部分 -->
                    <div class="page_name">
                        <div class="sig"></div>
                        <div class="sig_name">基础信息</div>
                    </div>
                    <div class="btn_cl"></div>
                </div>
                <!-- 输入框部分 -->
                <div class="from_main">
                    <div>

                    </div>
                    <el-form label-position="left" label-width="118px">
                        <div class="from_left">
                            <el-form-item label="姓名">
                                <el-input v-model="cust_name" placeholder="请输入客户姓名" clearable></el-input>
                                <div class="shugan"></div>
                            </el-form-item>
                            <el-form-item label="业务员">
                                <el-select v-model="sales_id" placeholder="请选择业务员" clearable>
                                    <el-option v-for="item in sales_arr" :key="item.uid" :label="item.name"
                                        :value="item.uid">
                                    </el-option>
                                </el-select>
                                <div class="shugan"></div>
                            </el-form-item>
                            <el-form-item label="等级">
                                <el-input v-model="level" placeholder="请输入客户等级(填:1-3)" clearable></el-input>
                                <div class="shugan"></div>
                            </el-form-item>
                            <el-form-item label="手机号">
                                <el-input v-model="mobile" placeholder="请输入客户手机号" :clearable="true"
                                    oninput="this.value = this.value.replace(/[^\d.]/g,'');"></el-input>
                                <div class="shugan"></div>
                            </el-form-item>
                        </div>
                        <div class="from_right">
                            <el-form-item label="地区">
                                <el-input v-model="adress" placeholder="请输入客户所在地区" clearable></el-input>
                                <div class="shugan"></div>
                            </el-form-item>
                            <el-form-item label="验证字段">
                                <el-input v-model="verify" placeholder="请输入验证字段" clearable></el-input>
                                <div class="shugan"></div>
                            </el-form-item>
                            <el-form-item label="电子保单" class="img_load">
                                <div style="display:flex">
                                    <el-input style="width:100px;height:44pxpx" placeholder="请上传保单" disabled></el-input>
                                    <el-upload class="upload-demo" action="https://customer.ztxinde.com/customer_upload"
                                        accept=".png,.gif,.jpg,.jpeg,.pdf" name="file" :show-file-list="true"
                                        :headers="myHeaders" :data="file_load" :before-upload="handleBefore"
                                        :on-success="handleSuccess" :on-preview="handlePreview" :file-list="fileList">
                                        <el-button
                                            style="border:none;height:44px;background:#6a8be8;color:#ffffff;width:152px;border-radius:0 6px 6px 0;font-size:16px"
                                            size="small">
                                            上传
                                        </el-button>
                                    </el-upload>
                                </div>
                                <div class="shugan"></div>
                            </el-form-item>
                        </div>
                    </el-form>
                </div>
            </div>
            <div class="maleft">
                <!-- 页面标题  按钮部分 -->
                <div class="top_btn">
                    <!-- 页面标题部分 -->
                    <div class="page_name">
                        <div class="sig"></div>
                        <div class="sig_name">车险信息</div>
                    </div>
                    <div class="btn_cl"></div>
                </div>
                <!-- 输入框部分 -->
                <div class="from_main">
                    <div v-for="(item,index) in car_ins" :key="index">
                        <el-form label-position="left" label-width="118px">
                            <div class="from_left">
                                <div style="color:#20252b;font-size:16px;font-weight:600;margin-bottom:10px">交强险</div>
                                <el-form-item label="起保日期">
                                    <!-- <el-input v-model="item.jq.start_date" placeholder="请输入起保日期(例:xxxx-xx-xx)"
                                        clearable></el-input> -->
                                    <el-date-picker v-model="item.jq.start_date" type="date" placeholder="请选择起保日期"
                                        format="yyyy-MM-dd" value-format="yyyy-MM-dd">
                                    </el-date-picker>
                                    <div class="shugan"></div>
                                </el-form-item>
                                <el-form-item label="终保日期">
                                    <!-- <el-input v-model="item.jq.end_date" placeholder="请输入终保日期(例:xxxx-xx-xx)" clearable>
                                    </el-input> -->
                                    <el-date-picker v-model="item.jq.end_date" type="date" placeholder="请选择终保日期"
                                        format="yyyy-MM-dd" value-format="yyyy-MM-dd">
                                    </el-date-picker>
                                    <div class="shugan"></div>
                                </el-form-item>
                                <el-form-item label="备注">
                                    <el-input v-model="item.jq.remark" placeholder="请输入备注" clearable></el-input>
                                    <div class="shugan"></div>
                                </el-form-item>
                                <el-form-item label="提醒设置">
                                    <el-input v-model="item.jq.remind_day" placeholder="请输入提醒时间(例:30天)" clearable>
                                    </el-input>
                                    <div class="shugan"></div>
                                </el-form-item>
                                <el-form-item label="预留字段1">
                                    <el-input v-model="item.jq.ext1" placeholder="请输入预留字段" clearable></el-input>
                                    <div class="shugan"></div>
                                </el-form-item>
                                <el-form-item label="预留字段2">
                                    <el-input v-model="item.jq.ext2" placeholder="请输入预留字段" clearable></el-input>
                                    <div class="shugan"></div>
                                </el-form-item>
                                <el-form-item label="预留字段3">
                                    <el-input v-model="item.jq.ext3" placeholder="请输入预留字段" clearable></el-input>
                                    <div class="shugan"></div>
                                </el-form-item>
                                <el-form-item label="同步到商业险" style="margin-bottom:50px">
                                    <el-radio v-model="item.jq.radio" label="1" @change="jpSync">是</el-radio>
                                    <el-radio v-model="item.jq.radio" label="2" @change="jpSync">否</el-radio>
                                </el-form-item>
                                <div class="carDiv"></div>
                                <el-form-item label="车牌号">
                                    <el-input v-model="item.car_number" placeholder="请输入车牌号" clearable></el-input>
                                    <div class="shugan"></div>
                                </el-form-item>
                                <el-form-item label="车架号">
                                    <el-input v-model="item.frame_number" placeholder="请输入车架号" clearable></el-input>
                                    <div class="shugan"></div>
                                </el-form-item>
                                <el-form-item label="初登日期">
                                    <!-- <el-input v-model="item.first_date" placeholder="请输入初登日期(例:xxxx-xx-xx)" clearable>
                                    </el-input> -->
                                    <el-date-picker v-model="item.first_date" type="date" placeholder="请选择初登日期"
                                        format="yyyy-MM-dd" value-format="yyyy-MM-dd">
                                    </el-date-picker>
                                    <div class="shugan"></div>
                                </el-form-item>
                            </div>
                            <div class="from_right">
                                <div style="color:#20252b;font-size:16px;font-weight:600;margin-bottom:10px">商业险</div>
                                <el-form-item label="起保日期">
                                    <!-- <el-input v-model="item.sy.start_date" placeholder="请输入起保日期(例:xxxx-xx-xx)"
                                        clearable></el-input> -->
                                    <el-date-picker v-model="item.sy.start_date" type="date" placeholder="请选择起保日期"
                                        format="yyyy-MM-dd" value-format="yyyy-MM-dd">
                                    </el-date-picker>
                                    <div class="shugan"></div>
                                </el-form-item>
                                <el-form-item label="终保日期">
                                    <!-- <el-input v-model="item.sy.end_date" placeholder="请输入终保日期(例:xxxx-xx-xx)" clearable>
                                    </el-input> -->
                                    <el-date-picker v-model="item.sy.end_date" type="date" placeholder="请选择终保日期"
                                        format="yyyy-MM-dd" value-format="yyyy-MM-dd">
                                    </el-date-picker>
                                    <div class="shugan"></div>
                                </el-form-item>
                                <el-form-item label="备注">
                                    <el-input v-model="item.sy.remark" placeholder="请输入备注" clearable></el-input>
                                    <div class="shugan"></div>
                                </el-form-item>
                                <el-form-item label="提醒设置">
                                    <el-input v-model="item.sy.remind_day" placeholder="请输入提醒时间(例:30天)" clearable>
                                    </el-input>
                                    <div class="shugan"></div>
                                </el-form-item>
                                <el-form-item label="预留字段1">
                                    <el-input v-model="item.sy.ext1" placeholder="请输入预留字段" clearable></el-input>
                                    <div class="shugan"></div>
                                </el-form-item>
                                <el-form-item label="预留字段2">
                                    <el-input v-model="item.sy.ext2" placeholder="请输入预留字段" clearable></el-input>
                                    <div class="shugan"></div>
                                </el-form-item>
                                <el-form-item label="预留字段3">
                                    <el-input v-model="item.sy.ext3" placeholder="请输入预留字段" clearable></el-input>
                                    <div class="shugan"></div>
                                </el-form-item>
                                <el-form-item label="同步到交强险" style="margin-bottom:50px">
                                    <el-radio v-model="item.sy.radio" label="1" @change="sy_Sync">是</el-radio>
                                    <el-radio v-model="item.sy.radio" label="2" @change="sy_Sync">否</el-radio>
                                </el-form-item>
                            </div>
                        </el-form>
                    </div>
                    <div style="text-align:left">
                        <el-button style="border:none;background:#6a8be8;color:#fff" @click="pushCar">添加车险信息
                        </el-button>
                        <el-button @click="deletCar">删除车险信息</el-button>
                    </div>
                </div>
            </div>
            <div class="maleft">
                <!-- 页面标题  按钮部分 -->
                <div class="top_btn">
                    <!-- 页面标题部分 -->
                    <div class="page_name">
                        <div class="sig"></div>
                        <div class="sig_name">非车险信息</div>
                    </div>
                    <div class="btn_cl"></div>
                </div>
                <!-- 输入框部分 -->
                <div class="from_main">
                    <div v-for="(item,index) in nocar_ins" :key="index">
                        <el-form label-position="left" label-width="118px">
                            <div class="from_left">
                                <el-form-item label="非车险">
                                    <el-input v-model="item.name" placeholder="请输入非车险名称" clearable></el-input>
                                    <div class="shugan"></div>
                                </el-form-item>
                                <el-form-item label="起保日期">
                                    <!-- <el-input v-model="item.start_date" placeholder="请输入起保日期(例:xxxx-xx-xx)" clearable>
                                    </el-input> -->
                                    <el-date-picker v-model="item.start_date" type="date" placeholder="请选择起保日期"
                                        format="yyyy-MM-dd" value-format="yyyy-MM-dd">
                                    </el-date-picker>
                                    <div class="shugan"></div>
                                </el-form-item>
                                <el-form-item label="终保日期">
                                    <!-- <el-input v-model="item.end_date" placeholder="请输入终保日期(例:xxxx-xx-xx)" clearable>
                                    </el-input> -->
                                    <el-date-picker v-model="item.end_date" type="date" placeholder="请选择终保日期"
                                        format="yyyy-MM-dd" value-format="yyyy-MM-dd">
                                    </el-date-picker>
                                    <div class="shugan"></div>
                                </el-form-item>
                                <el-form-item label="备注">
                                    <el-input v-model="item.remark" placeholder="请输入备注" clearable></el-input>
                                    <div class="shugan"></div>
                                </el-form-item>
                            </div>
                            <div class="from_right">
                                <el-form-item label="预留字段1">
                                    <el-input v-model="item.ext1" placeholder="请输入预留字段" clearable></el-input>
                                    <div class="shugan"></div>
                                </el-form-item>
                                <el-form-item label="预留字段2">
                                    <el-input v-model="item.ext2" placeholder="请输入预留字段" clearable></el-input>
                                    <div class="shugan"></div>
                                </el-form-item>
                                <el-form-item label="预留字段3">
                                    <el-input v-model="item.ext3" placeholder="请输入预留字段" clearable></el-input>
                                    <div class="shugan"></div>
                                </el-form-item>
                            </div>
                        </el-form>
                    </div>
                    <div style="text-align:left">
                        <el-button style="border:none;background:#6a8be8;color:#fff" @click="add_nocar">添加非车险信息
                        </el-button>
                        <el-button @click="del_nocar">删除非车险信息</el-button>
                    </div>
                </div>
            </div>
        </div>
        <!-- 提交重置按钮 -->
        <div class="btn_main">
            <el-button class="btn_rest" @click="ResterFn">重置</el-button>
            <el-button class="btn_submit" @click="SubmitFn">{{btn_name}}</el-button>
        </div>
    </div>
</template>

<script>
// let auth_token = sessionStorage.getItem("Token");
import { showCustomerApi, addCustomerApi, editCustomerApi } from '@/api'
export default {
    data () {
        return {
            // token
            myHeaders: {
                'token': ''
            },
            // 页面标题
            title: '',
            // 按钮名字
            btn_name: '',
            // 每条数据id
            id: '',
            // 有下级的id
            down_id: '',
            // 登录用户id
            user_id: '',
            // 有没有下级
            lower: '',
            // 客户姓名
            cust_name: '',
            // 业务员
            sales_id: '',
            sales_arr: [],
            // 等级
            level: '',
            // 手机号
            mobile: '',
            // 地区
            adress: '',
            // 验证字段
            verify: '',
            // 上传文件参数
            file_load: {
                auth_id: '',
                has_lower: '',
                lower_id: '',
            },
            // 电子保单
            load_url: [],
            file_url: '',
            fileList: [],
            // 车险数据
            car_ins: [],
            // 非车险数据
            nocar_ins: [],
        }
    },
    mounted () {
        this.id = this.$route.query.id;
        this.down_id = this.$route.query.lowerId;
        this.user_id = sessionStorage.getItem('id');
        this.lower = sessionStorage.getItem('lower');
        if (this.id == '添加客户') {
            this.title = '添加客户信息';
            this.btn_name = '确认添加';
            this.add_init();
        } else {
            this.title = '编辑客户信息';
            this.btn_name = '确认修改';
            this.edit_init();
        };
    },
    methods: {
        // 添加展示数据
        add_init () {
            showCustomerApi({
                lower_id: this.down_id,  //有下级id
                auth_id: this.user_id,   //登录用户id
                has_lower: this.lower,   //是否有下级
                type: 1
            }).then(res => {
                // console.log(res)
                if (res.status.code == 200) {
                    this.sales_arr = res.servicer;
                } else if (res.status.code == 201) {
                    this.$message.error(res.status.msg)
                } else if (res.status.code == 202) {
                    this.$message.error('登录过期，请重新登录！');
                    this.$router.push({ path: '/login' })
                }
            })
        },
        // 编辑展示数据
        edit_init () {
            showCustomerApi({
                lower_id: this.down_id,  //有下级id
                auth_id: this.user_id,   //登录用户id
                has_lower: this.lower,   //是否有下级
                type: 2,
                id: this.id,
            }).then(res => {
                // console.log(res)
                if (res.status.code == 200) {
                    this.sales_arr = res.servicer;
                    this.cust_name = res.data.name;  //姓名
                    this.mobile = res.data.phone;    //手机号
                    this.level = res.data.level;    //等级
                    this.adress = res.data.area;  //地区
                    this.verify = res.data.verify_field;
                    this.sales_id = res.data.servicer_id;  //业务员
                    this.car_ins = res.data.car;   //车险数据
                    this.car_ins.map(item => {
                        this.$set(item.jq, 'radio', '2');
                        this.$set(item.sy, 'radio', '2');
                    });
                    this.nocar_ins = res.data.no_car;   //非车险数据
                    this.load_url = res.data.elec_warranty;   //文件数据
                    this.load_url.map(item => {
                        let obj = {
                            'name': '',
                            'url': ''
                        };
                        if (item != '') {
                            obj.name = item;
                            obj.url = item
                            this.fileList.push(obj)
                        } else {
                            this.fileList = []
                        }
                    })
                } else if (res.status.code == 201) {
                    this.$message.error(res.status.msg)
                } else if (res.status.code == 202) {
                    this.$message.error('登录过期，请重新登录！');
                    this.$router.push({ path: '/login' })
                }
            })
        },
        // 上传文件前
        handleBefore (file) {
            this.myHeaders.token = sessionStorage.getItem('Token')
            this.file_load.auth_id = this.user_id;
            this.file_load.has_lower = this.lower;
            this.file_load.lower_id = this.down_id;
        },
        // 上传文件成功
        handleSuccess (res, file, fileList) {
            if (res.status.code == 200) {
                this.$message.success('文件上传成功');
                this.file_url = res.status.img;
                this.load_url.push(this.file_url);
                this.fileList = fileList;
            } else if (res.status.code == 201) {
                this.$message.error(res.status.msg)
            } else if (res.status.code == 202) {
                this.$message.error('登录过期，请重新登录！');
                this.$router.push({ path: '/login' })
            }
        },
        // 点击上传的文件
        handlePreview () {
            window.open('https://customer.ztxinde.com' + this.file_url)
        },
        // 点击添加车险信息
        pushCar () {
            let obj = {
                'car_number': '',
                'frame_number': '',
                'first_date': '',
                'type': '',
                'jq': {
                    'start_date': '',
                    'end_date': '',
                    'remark': '',
                    'remind_day': '',
                    'radio': '2',
                    'ext1': '',
                    'ext2': '',
                    'ext3': '',
                },
                'sy': {
                    'start_date': '',
                    'end_date': '',
                    'remark': '',
                    'remind_day': '',
                    'radio': '2',
                    'ext1': '',
                    'ext2': '',
                    'ext3': '',
                }
            };
            this.car_ins.push(obj);
        },
        // 删除车险信息
        deletCar () {
            this.car_ins.pop()
        },
        // 点击添加非车险信息
        add_nocar () {
            let obj1 = {
                'name': '',
                'start_date': '',
                'end_date': '',
                'remark': '',
                'ext1': '',
                'ext2': '',
                'ext3': '',
            };
            this.nocar_ins.push(obj1);
        },
        // 点击删除非车险信息
        del_nocar () {
            this.nocar_ins.pop()
        },
        // 交强同步
        jpSync (e) {
            if (e == 1) {
                this.car_ins.map(item => {
                    item.sy = Object.assign({}, item.jq)
                });

            } else {
                this.car_ins.map(item => {
                    // item.sy != item.jq;
                    item.sy.start_date = '';
                    item.sy.end_date = '';
                    item.sy.remark = '';
                    item.sy.remind_day = '';
                    item.sy.radio = '2';
                    item.sy.ext1 = '';
                    item.sy.ext2 = '';
                    item.sy.ext3 = '';
                })
            }
        },
        // 商业同步
        sy_Sync (e) {
            if (e == 1) {
                this.car_ins.map(item => {
                    item.jq = Object.assign({}, item.sy)
                })
            } else {
                this.car_ins.map(item => {
                    // return item.jq != item.sy;
                    item.jq.start_date = '';
                    item.jq.end_date = '';
                    item.jq.remark = '';
                    item.jq.remind_day = '';
                    item.jq.radio = '2';
                    item.jq.ext1 = '';
                    item.jq.ext2 = '';
                    item.jq.ext3 = '';
                })
            }
        },
        // 点击重置按钮
        ResterFn () {
            this.cust_name = '';
            this.sales_id = '';
            this.level = '';
            this.mobile = '';
            this.adress = '';
            this.verify = '';
            this.load_url = [];
            this.fileList = [];
            this.car_ins = [];
            this.nocar_ins = [];
        },
        // 点击返回按钮返回
        handle_goback () {
            this.$router.push({ path: '/custom' })
        },
        // 点击提交按钮
        SubmitFn () {
            this.car_ins.map(item => {
                // console.log(item.jq)
                if (item.jq.start_date != '' && item.sy.start_date != '') {
                    item.type = 3;
                } else if (item.sy.start_date != '') {
                    item.type = 2;
                } else if (item.jq.start_date != '') {
                    item.type = 1;
                }
            });
            if (this.id == '添加客户') {
                addCustomerApi({
                    auth_id: this.user_id,  //登陆用户名
                    lower_id: this.down_id,  //有下级id
                    has_lower: this.lower,  //有没有下级
                    name: this.cust_name,    //客户姓名
                    phone: this.mobile,  // 手机号
                    servicer_id: this.sales_id,   //业务员
                    area: this.adress,   //地址
                    level: this.level,   //等级
                    elec_warranty: this.load_url,   //电子保单
                    car: this.car_ins,   //车险
                    no_car: this.nocar_ins,   //非车险
                    verify_field: this.verify,  //验证
                }).then(res => {
                    // console.log(res)
                    if (res.status.code == 200) {
                        this.$message.success('添加成功');
                        this.$router.push({ path: '/custom' })
                        this.ResterFn();
                    } else if (res.status.code == 201) {
                        this.$message.error(res.status.msg)
                    } else if (res.status.code == 202) {
                        this.$message.error('登录过期，请重新登录！');
                        this.$router.push({ path: '/login' })
                    }
                })
            } else {
                editCustomerApi({
                    id: this.id,
                    auth_id: this.user_id,  //登陆用户名
                    lower_id: this.down_id,  //有下级id
                    has_lower: this.lower,  //有没有下级
                    name: this.cust_name,    //客户姓名
                    phone: this.mobile,  // 手机号
                    servicer_id: this.sales_id,   //业务员
                    area: this.adress,   //地址
                    level: this.level,   //等级
                    elec_warranty: this.load_url,   //电子保单
                    car: this.car_ins,   //车险
                    no_car: this.nocar_ins,   //非车险
                    verify_field: this.verify,  //验证
                }).then(res => {
                    // console.log(res)
                    if (res.status.code == 200) {
                        this.$message.success('修改成功');
                        this.$router.push({ path: '/custom' })
                        this.ResterFn();
                    } else if (res.status.code == 201) {
                        this.$message.error(res.status.msg)
                    } else if (res.status.code == 202) {
                        this.$message.error('登录过期，请重新登录！');
                        this.$router.push({ path: '/login' })
                    }
                })
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.right_main {
    width: 100%;
    height: 100%;
    .msg {
        text-align: left;
        color: #000000;
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 16px;
    }
    .rige {
        .maleft {
            background: #ffffff;
            border-radius: 20px;
            margin-bottom: 32px;
            //  页面标题  按钮部分
            .top_btn {
                display: flex;
                justify-content: space-between;
                // align-items: center;
                padding: 24px;
                border-bottom: 1px solid #e8e9f1;
                // 页面标题部分
                .page_name {
                    display: flex;
                    align-items: center;
                    .sig {
                        border-radius: 2px;
                        width: 4px;
                        height: 16px;
                        background: #6a8be8;
                        margin-right: 6px;
                    }
                    .sig_name {
                        font-size: 16px;
                        font-weight: 600;
                        color: #000000;
                    }
                }
                .btn_cl {
                    width: 10px;
                }
            }
            // 输入框部分
            .from_main {
                padding: 24px;
                .el-form {
                    text-align: left;
                    display: flex;
                    .from_left {
                        position: relative;
                        .el-button {
                            width: 180px;
                        }
                        .carDiv {
                            width: 420%;
                            height: 2px;
                            background: #e8e9f1;
                            position: absolute;
                            left: -30px;
                            top: 574px;
                        }
                    }
                    .from_right {
                        margin-left: 56px;
                        //照片上传
                        .img_load {
                            width: 218px;
                            border-radius: 6px 0 0 6px;
                            /deep/.el-input__inner {
                                width: 100px;
                                height: 44px;
                                background: #ffffff;
                            }
                            /deep/.el-upload-list {
                                position: absolute;
                                left: -100px;
                                top: 50px;
                                /deep/.el-upload-list__item-status-label {
                                    position: absolute;
                                    right: -20px;
                                    top: 0;
                                    /deep/.el-icon-close {
                                        position: absolute;
                                        right: -20px;
                                        top: 5px;
                                    }
                                    /deep/.el-icon-close-tip {
                                        display: none !important;
                                    }
                                }
                            }
                        }
                    }
                    .el-form-item {
                        width: 370px;
                        position: relative;
                        border: 1px solid #d9d9d9;
                        border-radius: 6px;
                        /deep/.el-form-item__label {
                            text-align: center;
                            font-size: 14px;
                            font-weight: 500;
                            color: #333333;
                        }
                        .el-input {
                            border: none;
                            width: 252px;
                            height: 44px;
                        }
                        /deep/.el-input__inner {
                            border: none;
                            width: 252px;
                            height: 44px;
                        }
                        .shugan {
                            background: #d3d3d6;
                            width: 2px;
                            height: 16px;
                            position: absolute;
                            right: 258px;
                            top: 12px;
                        }
                    }
                }
            }
        }
    }
    // 提交 重置按钮部分
    .btn_main {
        text-align: left;
        // padding: 20px 32px;
        // background: #ffffff;
        // border-radius: 20px;
        .el-button {
            width: 160px;
            height: 40px;
            border-radius: 6px;
            font-size: 14px;
            font-weight: 400;
            text-align: center;
        }
        .btn_submit {
            border: none;
            background: #6a8be8;
            margin-left: 8px;
            color: #ffffff;
        }
        .btn_rest {
            border: 1px solid #d9d9d9;
            color: #20252b;
            background: #ffffff;
        }
    }
}
</style>